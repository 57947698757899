import * as React from 'react'
import { Resource } from '@byll/hermes'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Spinner } from 'components/Spinner'
import { IResidentAutoArchive } from 'contracts/residents/interfaces/IResidentAutoArchive'
import { Button } from 'components/Form/components/Button'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import dayjs from 'dayjs'
import { RoundIcon } from 'components/RoundIcon'

interface Props {}

@observer
export class DashboardAutoArchive extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly status: Resource<IResidentAutoArchive>
  private readonly disposers: Disposer[] = []
  @observable private downloadingArchive = false
  @observable private downloadingDelete = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.status = new Resource(
      `/api/${context.instance.id}/residentAutoArchive/${context.instance.id}`,
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.status.init())
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  @action private setArchiveEnabled = () => {
    if (this.status.data) {
      this.status.data.nightlyAutoArchiveEnabled = true
    }
  }

  @action private setArchiveDisabled = () => {
    if (this.status.data) {
      this.status.data.nightlyAutoArchiveEnabled = false
    }
  }

  @action private setDeleteEnabled = () => {
    if (this.status.data) {
      this.status.data.nightlyAutoDeleteEnabled = true
    }
  }

  @action private setDeleteDisabled = () => {
    if (this.status.data) {
      this.status.data.nightlyAutoDeleteEnabled = false
    }
  }

  private downloadArchive = async () => {
    if (this.downloadingArchive || !this.status.data) {
      return
    }
    runInAction(() => (this.downloadingArchive = true))
    createAndDownloadReport(
      'residents-for-auto-archive',
      this.context.instance.id,
      {
        type: 'Archivierungslauf',
        lastEndOfResponsibilityBefore: dayjs()
          .add(-this.status.data.residentArchiveSchedule, 'months')
          .format('YYYY-MM-DD'),
      },
      `Archivierungslauf ${dayjs().add(1, 'day').format('DD.MM.YYYY')}.xlsx`,
    )
    runInAction(() => (this.downloadingArchive = false))
  }

  private downloadDelete = async () => {
    if (this.downloadingDelete || !this.status.data) {
      return
    }
    runInAction(() => (this.downloadingDelete = true))
    createAndDownloadReport(
      'residents-for-auto-archive',
      this.context.instance.id,
      {
        type: 'Löschlauf',
        lastEndOfResponsibilityBefore: dayjs()
          .add(-this.status.data.residentDeleteSchedule, 'months')
          .format('YYYY-MM-DD'),
      },
      `Löschlauf ${dayjs().add(1, 'day').format('DD.MM.YYYY')}.xlsx`,
    )
    runInAction(() => (this.downloadingDelete = false))
  }

  render() {
    return (
      <div className='bg-white rounded-md shadow-md overflow-hidden flex flex-col'>
        {/* Archive */}
        <div className='p-4 flex-auto'>
          <div className='font-bold text-xl mb-2'>
            Bewohnerarchiv{' '}
            <RoundIcon
              onClick={this.downloadArchive}
              icon={
                this.downloadingArchive ? 'fas fa-spin fa-spinner' : 'fas fa-download'
              }
              color='primary'
              tooltip={{
                position: 'bottom',
                text: 'Vorschau nächster Archivierungslauf',
              }}
            />
          </div>
          <div className='relative'>
            {!this.status.data && <Spinner />}
            {this.status.data && (
              <div>
                <div className='text-gray-500 my-4'>
                  {`Bei Aktivierung werden Bewohner automatisch archiviert, deren Zuständigkeit seit über ${
                    this.status.data.residentArchiveSchedule
                  } ${
                    this.status.data.residentArchiveSchedule === 1 ? 'Monat' : 'Monaten'
                  } beendet ist - täglich um 00:00.`}
                </div>
                <div className='flex'>
                  <Button
                    onClick={this.setArchiveDisabled}
                    color={
                      !this.status.data.nightlyAutoArchiveEnabled
                        ? 'primary'
                        : 'secondary'
                    }
                    outline={this.status.data.nightlyAutoArchiveEnabled}
                    className='flex-auto'
                    style={{ borderRadius: '6px 0 0 6px', padding: '9px 0' }}
                  >
                    Deaktiviert
                  </Button>
                  <Button
                    onClick={this.setArchiveEnabled}
                    color={
                      this.status.data.nightlyAutoArchiveEnabled ? 'success' : 'secondary'
                    }
                    outline={!this.status.data.nightlyAutoArchiveEnabled}
                    className='flex-auto'
                    style={{ borderRadius: '0 6px 6px 0', padding: '9px 0' }}
                  >
                    Aktiviert
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Delete */}
        <div className='p-4 flex-auto'>
          <div className='font-bold text-xl mb-2'>
            Löschung{' '}
            <RoundIcon
              onClick={this.downloadDelete}
              icon={
                this.downloadingArchive ? 'fas fa-spin fa-spinner' : 'fas fa-download'
              }
              color='primary'
              tooltip={{ position: 'bottom', text: 'Vorschau nächster Löschlauf' }}
            />
          </div>
          <div className='relative'>
            {!this.status.data && <Spinner />}
            {this.status.data && (
              <div>
                <div className='text-gray-500 my-4'>
                  {`Bei Aktivierung werden Bewohner, die seit ${
                    this.status.data.residentDeleteSchedule
                  } ${
                    this.status.data.residentDeleteSchedule === 1 ? 'Monat' : 'Monaten'
                  } archiviert sind, automatisch vollständig gelöscht - täglich um 00:00.`}
                </div>
                <div className='flex'>
                  <Button
                    onClick={this.setDeleteDisabled}
                    color={
                      !this.status.data.nightlyAutoDeleteEnabled ? 'primary' : 'secondary'
                    }
                    outline={this.status.data.nightlyAutoDeleteEnabled}
                    className='flex-auto'
                    style={{ borderRadius: '6px 0 0 6px', padding: '9px 0' }}
                  >
                    Deaktiviert
                  </Button>
                  <Button
                    onClick={this.setDeleteEnabled}
                    color={
                      this.status.data.nightlyAutoDeleteEnabled ? 'success' : 'secondary'
                    }
                    outline={!this.status.data.nightlyAutoDeleteEnabled}
                    className='flex-auto'
                    style={{ borderRadius: '0 6px 6px 0', padding: '9px 0' }}
                  >
                    Aktiviert
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
